
import Vue from 'vue';
import ImageDrums from '@/assets/img/banner-drums.png';
import UpsetDrums from '@/assets/img/banner-upset.png';

interface BannerData {
  image: string
  title: string
  width: string
  height: string
  description: string,
  dataTest?: string,
}

export default Vue.extend({
  name: 'Banner',

  props: {
    type: {
      type: String,
      default: 'success',
    },
  },

  computed: {
    bannerData(): BannerData {
      switch (this.type) {
        case 'success':
          return {
            image: ImageDrums,
            title: this.$t('CourseView.Banner.course.success.title'),
            description: this.$t('CourseView.Banner.course.success.description'),
            width: '57',
            height: '57',
            dataTest: 'congratulation',
          };
        case 'overdue':
          return {
            image: UpsetDrums,
            title: this.$t('CourseView.Banner.course.overdue.title'),
            description: this.$t('CourseView.Banner.course.overdue.description'),
            width: '44',
            height: '44',
            dataTest: 'time-is-over',
          };
        default:
          return {
            image: ImageDrums,
            title: this.$t('CourseView.Banner.default.title'),
            description: this.$t('CourseView.Banner.default.description'),
            width: '57',
            height: '57',
          };
      }
    },
  },
});
