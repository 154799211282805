import {
  computed, Ref, ref, watch,
} from 'vue';
import useCourseSession from '@/domains/course/composables/useCourseSession';
import * as AssignmentService from '@/services/api/lxp-assignment';
import * as CatalogueService from '@/services/api/catalogue';
import { useDi } from '@/plugins';
import { useI18n } from '@/plugins/vue-i18n';
import { IAssignment } from '@/domains/assignment';
import useCourseSteps from '@/domains/course/composables/useCourseSteps';
import * as TracksService from '@/services/api/tracks';
import { IAtom } from '@/domains/atom';

type IUseCourseViewProps = {
  trackSessionId: Ref<number>
};

export default function useCourseView({ trackSessionId }: IUseCourseViewProps) {
  const di = useDi();
  const { t } = useI18n();

  const {
    isSessionCompleted,
    isSessionFailed,
    isSessionExpires,
    isSessionActive,
    hasFinishDate,
    assignment,
    trackSession,
    course,
    steps,
    sections,
    hierarchy,
    fetch: fetchSession,
    increaseFinishedStepsCount,
  } = useCourseSession({ trackSessionId });

  const {
    stepsTree,
    stepsTreeChildren,
    currentStepId,
    currentStep,
    isLinearFlow,
    isSomeStepStarted,
    isNextStepAvailable,
    isPrevStepAvailable,
    isCurrentStepCompleted,
    init: initCourseSteps,
    runCurrentStep,
    setCurrentStep,
    onStepNextHandler,
    onStepPrevHandler,
    onStepCompleteHandler,
    onStepSetHandler,
  } = useCourseSteps({
    trackSession,
    trackSessionId,
    course,
    steps,
    sections,
    hierarchy,
    fetch: fetchSession,
    increaseFinishedStepsCount,
    assignment,
  });

  const assignments = ref<IAssignment[]>([]);
  const atom = ref<IAtom>();
  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);
  const hasNewVersion = ref<boolean>(false);

  const fetchAssignments = async () => {
    const assignmentsGet = await AssignmentService.userAssignmentsGet();
    const fullAssignmentsList = assignmentsGet.adminAssignments
      .concat(assignmentsGet.assignmentsInProgress)
      .concat(assignmentsGet.selfAssignments)
      .concat(assignmentsGet.finishedAssignments);
    assignments.value = [...new Map(fullAssignmentsList.map((item) => [item.catalogueAtomMainId, item])).values()];
  };

  const fetchAtom = async () => {
    const id = assignment.value?.catalogueAtomId;
    if (id) {
      try {
        atom.value = await CatalogueService.atomGet(id);
      } catch (e) {
        di.notify.error({
          content: t('CourseView.errors.atom'),
        });
      }
    }
  };

  const checkNewVersion = async () => {
    try {
      const response = await TracksService.trackCheckNewVersion(trackSessionId.value);
      hasNewVersion.value = response.hasNewVersion;
    } catch (error: any) {
      console.error(error);
    }
  };

  const currentAssignment = computed(() => {
    return assignments.value?.find(({ catalogueAtomMainId }) => catalogueAtomMainId === atom.value?.mainId);
  });

  const totalStepsCount = computed(() => trackSession.value?.totalStepsCount || 0);

  const isSelfAssignment = computed(() => {
    // FIX: если программы нет в adminAssignments, то кнопка не появится, хотя программа самоназначена из каталога
    return currentAssignment.value?.selfAssigned || false;
  });

  const assignmentSessionId = computed(() => {
    return Number(assignment.value?.assignmentSessionId);
  });

  const init = async () => {
    isLoading.value = true;

    try {
      await fetchSession();
      await fetchAtom();
      await fetchAssignments();

      if (isSessionCompleted.value) {
        await checkNewVersion();
      }
    } catch (e) {
      isError.value = true;
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  };

  const refreshSession = () => {
    init();
    checkNewVersion();
  };

  watch([trackSessionId], refreshSession);

  return {
    atom,
    assignment,
    hasNewVersion,
    totalStepsCount,
    isSessionCompleted,
    isSessionFailed,
    isSessionExpires,
    isSessionActive,
    hasFinishDate,
    stepsTree,
    stepsTreeChildren,
    currentStepId,
    currentStep,
    isLinearFlow,
    isSomeStepStarted,
    isNextStepAvailable,
    isPrevStepAvailable,
    isCurrentStepCompleted,
    initCourseSteps,
    runCurrentStep,
    setCurrentStep,
    onStepNextHandler,
    onStepPrevHandler,
    onStepCompleteHandler,
    onStepSetHandler,
    isSelfAssignment,
    currentAssignment,
    assignmentSessionId,
    isLoading,
    isError,
    init,
  };
}
