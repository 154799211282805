import {
  computed, Ref, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import { nextSibling } from '@/common/tree/nextSibling';
import {
  ICourseAssignment, ICourseStepState, isCourseStepAtom, parseCourseTree,
} from '@/domains/course';
import { prevSibling } from '@/common/tree/prevSibling';
import { PlayerSessionStatus, UUID } from '@/domains/common';
import {
  ITree, findBy, getFirstLeaf, isDepth,
} from '@/common/tree';
import { dfs } from '@/common/tree/dfs';
import { isParentOf } from '@/common/tree/isParentOf';
import { trackCurrentStepGet, trackStepRun } from '@/services/api/tracks';
import { getRouteNameForAtomType } from '@/helpers/getRouteNameForAtomType';
import { Names } from '@/plugins/vue-router';
import { IUseCourseSessionState } from '@/domains/course/composables/useCourseSession';
import { sleep } from '@/helpers';
import { AtomType } from '@/domains/atom';

export default function useCourseSteps({
  trackSession,
  trackSessionId,
  course,
  steps,
  sections,
  hierarchy,
  fetch,
  increaseFinishedStepsCount,
  assignment,
}: Pick<
  IUseCourseSessionState,
  | 'trackSession'
  | 'trackSessionId'
  | 'course'
  | 'steps'
  | 'sections'
  | 'hierarchy'
  | 'fetch'
  | 'increaseFinishedStepsCount'
> & {
  assignment: Ref<ICourseAssignment | undefined>,
}) {
  const router = useRouter();
  const tree = ref<ITree<ICourseStepState>>();

  const isLoading = ref(false);

  const currentStepId = ref<UUID | null>(null);
  const currentStep = computed(() => findBy(tree.value, 'id', currentStepId.value));

  const nextStep = computed(() => nextSibling(currentStep.value, isCourseStepAtom));
  const prevStep = computed(() => prevSibling(currentStep.value, isCourseStepAtom));
  const isCurrentStepCompleted = computed(() => {
    return (
      currentStep.value?.current
      && isCourseStepAtom(currentStep.value.current)
      && currentStep.value.current.stepSessionStatus === PlayerSessionStatus.COMPLETED
    );
  });
  const isLinearFlow = computed(() => course.value?.linearFlow);
  const isNextStepAvailable = computed(() =>
    (isLinearFlow.value ? isCurrentStepCompleted.value && Boolean(nextStep.value) : Boolean(nextStep.value)));

  const isPrevStepAvailable = computed(() => Boolean(prevStep.value));

  const isSomeStepStarted = computed(() =>
    steps.value.some((step) =>
      [PlayerSessionStatus.ACTIVE, PlayerSessionStatus.COMPLETED].includes(step.stepSessionStatus)));

  const stepsTree = computed(() => {
    let isDisabled = false;
    let sectionOrderNumber = 0;

    dfs(tree.value, (node) => {
      if (!node.current) return true;

      if (isCourseStepAtom(node.current)) {
        const isCurrent = node.current.id === currentStepId.value;
        const isCompleted = node.current.stepSessionStatus === PlayerSessionStatus.COMPLETED
          && node.current.attemptStatus !== 'failed';
        const isFailed = node.current.attemptStatus === 'failed'
          && node.current.stepSessionStatus === PlayerSessionStatus.ACTIVE;
        const isWasted = node.current.attemptStatus === 'failed'
          && node.current.stepSessionStatus === PlayerSessionStatus.COMPLETED;

        // eslint-disable-next-line no-param-reassign
        node.current = {
          ...node.current,
          isActive: isCurrent,
          isDisabled: !isCompleted && isDisabled,
          isCompleted,
          isFailed,
          isWasted,
        };

        if (isLinearFlow.value && !isCompleted) {
          isDisabled = true;
        }
      } else {
        if (isDepth(node, 1)) sectionOrderNumber += 1;

        // eslint-disable-next-line no-param-reassign
        node.current = {
          ...node.current,
          orderNumber: sectionOrderNumber,
          progress: (node.current.finishedStepsCount / node.current.totalStepsCount) * 100,
          isActive: currentStep.value ? isParentOf(node, currentStep.value) : false,
          isDisabled,
          isOpened: true,
        };
      }

      return true;
    });

    return tree.value;
  });

  const setCurrentStep = async (stepId: UUID) => {
    const node = findBy<ICourseStepState>(stepsTree.value, 'id', stepId);

    if (!node) return;

    const step = node.current;

    if (!isCourseStepAtom(step)) {
      console.error('Попытка запустить не правильную сущность. Это не шаг.');
      return;
    }

    if (step.id === currentStepId.value) {
      console.error('Попытка запустить тот же шаг');
      return;
    }

    let { playerSessionId } = step;

    if (!playerSessionId) {
      try {
        isLoading.value = true;

        const stepSession = await trackStepRun({
          trackSessionId: trackSessionId.value,
          stepId: step.id,
        });

        playerSessionId = stepSession.playerSessionId;
      } catch (e) {
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    }

    currentStepId.value = stepId;

    await router.push({
      name: getRouteNameForAtomType(step.atomType),
      params: {
        trackSessionId: String(trackSessionId.value),
        stepId: step.id,
        playerSessionId: String(playerSessionId),
        assignmentId: String(assignment.value?.assignmentSessionId),
      },
    });
  };

  const init = async () => {
    const stepsValue = (steps?.value || []) as ICourseStepState[];
    const sectionsValue = (sections?.value || []) as ICourseStepState[];
    tree.value = parseCourseTree<ICourseStepState>(hierarchy?.value || [], [...stepsValue, ...sectionsValue], {
      linearFlow: false,
    });
  };

  const onStepNextHandler = async () => {
    if (!nextStep || !nextStep.value) {
      await router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
        params: {
          trackSessionId: String(trackSessionId.value),
          playerSessionId: String(trackSessionId.value),
          atomType: AtomType.TRACK,
        },
      });

      return;
    }

    await setCurrentStep(nextStep.value.current.id);
  };

  const onStepPrevHandler = async () => {
    if (!prevStep || !prevStep.value) return;

    await setCurrentStep(prevStep.value.current.id);
  };

  const onStepCompleteHandler = async () => {
    isLoading.value = true;
    await sleep(1000);
    await fetch();
    isLoading.value = false;

    if (!currentStep.value) return;

    if (!isCourseStepAtom(currentStep.value.current)) return;

    if (currentStep.value.current.stepSessionStatus !== PlayerSessionStatus.COMPLETED) {
      if (trackSession.value) {
        increaseFinishedStepsCount();
      }
      currentStep.value.current.stepSessionStatus = PlayerSessionStatus.COMPLETED;
    }
  };

  const onStepSetHandler = async ({ id }: { id: UUID }) => {
    await setCurrentStep(id);
  };

  const runCurrentStep = async () => {
    /**
     * Если шаг не указан или он не верный нужно запросить активный шаг
     * */
    if (!currentStepId.value) {
      const { stepId } = await trackCurrentStepGet({
        trackSessionId: trackSessionId.value,
      });

      /**
       * Если нет ни одного активного шага нет, стартуем первый
       * */
      if (!stepId) {
        const firstLeaf = getFirstLeaf(stepsTree.value);

        if (!firstLeaf) {
          return;
        }
        await trackStepRun({
          trackSessionId: trackSessionId.value,
          stepId: firstLeaf?.current.id,
        });

        setCurrentStep(firstLeaf?.current.id);

        console.error('Странно, но курс не имеет шагов');
      } else {
        setCurrentStep(stepId);
      }
    }
  };

  watch(steps, async () => {
    await init();
  });

  const stepsTreeChildren = computed(() => stepsTree.value?.children ?? []);

  return {
    isLoading,
    stepsTree,
    stepsTreeChildren,
    currentStepId,
    currentStep,
    isLinearFlow,
    isSomeStepStarted,
    isNextStepAvailable,
    isPrevStepAvailable,
    isCurrentStepCompleted,
    init,
    runCurrentStep,
    setCurrentStep,
    onStepNextHandler,
    onStepPrevHandler,
    onStepCompleteHandler,
    onStepSetHandler,
  };
}
