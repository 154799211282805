
import { defineComponent, onMounted, toRefs } from 'vue';

import TTBackButton from '@/components/ui/TTBackButton.vue';
import Banner from '@/components/tracks/Banner';
import DialogComponent from '@/components/ui/DialogComponent';
import CoursePage from '@/views/App/Assignments/CoursePage';
import * as AssignmentService from '@/services/api/lxp-assignment';
import { Banner as DialogBanner } from '@/types/banner';
import { UUID } from '@/domains/common';
import { AtomType } from '@/domains/atom';
import useCourseView from '@/domains/course/composables/useCourseView';
import IDeadlineChipInfo from '../../Assignments/CoursePage/types';

export default defineComponent({
  name: 'CourseView',

  components: {
    TTBackButton,
    Banner,
    DialogComponent,
    CoursePage,
  },

  inject: ['Names'],

  props: {
    trackSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { trackSessionId } = toRefs(props);

    const {
      atom,
      assignment,
      hasNewVersion,
      totalStepsCount,
      isSessionCompleted,
      isSessionFailed,
      isSessionExpires,
      isSessionActive,
      hasFinishDate,
      stepsTreeChildren,
      currentStepId,
      currentStep,
      isLinearFlow,
      isSomeStepStarted,
      isNextStepAvailable,
      isPrevStepAvailable,
      isCurrentStepCompleted,
      initCourseSteps,
      runCurrentStep,
      setCurrentStep,
      onStepNextHandler,
      onStepPrevHandler,
      onStepCompleteHandler,
      onStepSetHandler,
      isSelfAssignment,
      currentAssignment,
      assignmentSessionId,
      isLoading,
      isError,
      init,
    } = useCourseView({ trackSessionId });

    onMounted(() => {
      init();
    });

    return {
      atom,
      assignment,
      hasNewVersion,
      totalStepsCount,
      isSessionCompleted,
      isSessionFailed,
      isSessionExpires,
      isSessionActive,
      hasFinishDate,
      stepsTreeChildren,
      currentStepId,
      currentStep,
      isLinearFlow,
      isSomeStepStarted,
      isNextStepAvailable,
      isPrevStepAvailable,
      isCurrentStepCompleted,
      initCourseSteps,
      runCurrentStep,
      setCurrentStep,
      onStepNextHandler,
      onStepPrevHandler,
      onStepCompleteHandler,
      onStepSetHandler,
      isSelfAssignment,
      currentAssignment,
      assignmentSessionId,
      isLoading,
      isError,
      init,
    };
  },

  computed: {
    dialogTitle(): string {
      return this.$t('WillStudy.dialog.remove.course.title');
    },

    dialogText(): string {
      return this.$t('WillStudy.dialog.remove.course.text');
    },

    mainButtonProperties(): { text: string; dataTestLabel: string } {
      if (this.isSessionCompleted) {
        return {
          text: this.$t('CourseView.mainButtonText.again'),
          dataTestLabel: 'repeat',
        };
      }

      if (this.isSessionActive && this.isSomeStepStarted) {
        return {
          text: this.$t('CourseView.mainButtonText.continue'),
          dataTestLabel: 'continue',
        };
      }

      return {
        text: this.$t('CourseView.mainButtonText.start'),
        dataTestLabel: 'start',
      };
    },

    showDeadlineChip(): boolean {
      return this.hasFinishDate && !this.isSessionCompleted;
    },

    deadlineHumanized(): string {
      return this.deadlineHumanizedCourse;
    },

    deadlineHumanizedTrack(): string {
      const deadline = this.$dayjs(this.assignment?.finishDate);

      if (deadline.isToday()) {
        return this.$t('AssignedTracksCardDeadline.completeToday');
      }
      if (deadline.isTomorrow()) {
        return this.$t('AssignedTracksCardDeadline.completeTomorrow');
      }
      return this.$t('AssignedTracksCardDeadline.completeBefore', { date: deadline.format('DD MMMM') });
    },

    deadlineHumanizedCourse(): string {
      const deadline = this.$dayjs(this.assignment?.finishDate);
      const time = deadline.format('HH:mm');
      const fullDate = deadline.format('DD MMMM HH:mm');

      if (this.isDeadlineFailed) {
        return this.$t('AssignedTracksCardDeadline.failed');
      }

      if (deadline.isToday()) {
        return this.$t('AssignedTracksCardDeadline.course.completeToday', { time });
      }

      if (deadline.isTomorrow()) {
        return this.$t('AssignedTracksCardDeadline.course.completeTomorrow', { time });
      }

      return this.$t('AssignedTracksCardDeadline.course.completeBefore', { date: fullDate });
    },

    isDeadlineFailed(): boolean {
      const now = this.$dayjs();
      const deadline = this.$dayjs(this.assignment?.finishDate);

      return now > deadline;
    },

    deadlineChipInfo(): IDeadlineChipInfo {
      switch (true) {
        case !this.showDeadlineChip:
          return {
            icon: '',
            type: '',
            text: '',
          };

        case this.isSessionFailed:
          return {
            icon: '$exclamationCircle',
            type: 'new-danger',
            text: this.$t('AssignedTracksCardDeadline.failed'),
          };

        case this.isSessionExpires:
          return {
            icon: '$calendarSolid',
            type: 'new-danger',
            text: this.deadlineHumanized,
          };

        default:
          return {
            icon: '$calendarSolid',
            type: 'default',
            text: this.deadlineHumanized,
          };
      }
    },

    bannerType(): string {
      if (this.isSessionCompleted) {
        return 'success';
      }

      if (this.isSessionFailed || this.isDeadlineFailed) {
        return 'overdue';
      }
      return '';
    },

    actionMenu(): Record<string, string | (() => void)>[] {
      return this.isSelfAssignment
        ? [
          {
            value: 'delete',
            title: this.$t('CourseView.actionMenu.delete'),
            icon: '$remove',
            color: 'tt-light-red--text base',
            action: this.openDialog,
          },
        ]
        : [];
    },
  },

  methods: {
    async runStep(data: { stepId: UUID; atomType: AtomType }) {
      await this.setCurrentStep(data.stepId);
    },

    async onUnassignTrackHandler() {
      if (!this.currentAssignment) {
        console.error('[onUnassignTrackHandler]', 'Can\'t unassign', this.currentAssignment);
        return;
      }

      try {
        const { assignmentUserId, catalogueAtomMainId } = this.currentAssignment;

        await AssignmentService.userTrackUnassign(assignmentUserId);

        if (catalogueAtomMainId) {
          this.$router.push({
            name: this.Names.R_APP_LEARNING_ATOM_PREVIEW,
            params: {
              catalogueAtomId: catalogueAtomMainId,
            },
          });
        } else {
          this.$router.push({
            name: this.Names.R_APP_LEARNING_TRACKS,
          });
        }
      } catch (e) {
        console.error(e);
      }
    },

    rejectDialog() {
      (this.$refs.dialogRemove as DialogBanner).close();
    },

    openDialog() {
      (this.$refs.dialogRemove as DialogBanner).open();
    },
  },
});
